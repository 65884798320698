const fetchWithAuth = async (url, options = {}) => {
  const storedUser = localStorage.getItem('user');
  
  if (!storedUser) {
    console.error('No se encontró el usuario en el almacenamiento local.');
    throw new Error('No se encontró el usuario en el almacenamiento local.');
  }

  const { token } = JSON.parse(storedUser); // Extraer el token del objeto almacenado
  console.log('stored token:', token);

  if (!options.headers) {
    options.headers = {};
  }

  options.headers['Authorization'] = `Bearer ${token}`;
  options.headers['Content-Type'] = 'application/json';

  const response = await fetch(url, options);

  if (!response.ok) {
    const errorText = await response.text();
    console.error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
  }

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return response.json();
  } else {
    return response.text();
  }
};

export default fetchWithAuth;

  