import React from 'react';
import './PhotoGranulometry.css';

function PhotoGranulometry({ photos, selectedPhoto, handleSelectPhoto }) {
  // Encontrar la foto seleccionada
  const selectedPhotoId = selectedPhoto?.id_foto_s3;

  return (
    <div className="photo-table-container">
      {/* Selector para elegir la foto por nombre */}
      <div className="photo-selector-container">
        <label htmlFor="photoSelector">Seleccionar Foto: </label>
        <select 
          id="photoSelector" 
          onChange={(e) => {
            const selectedPhoto = photos.find(photo => photo.id_foto_s3 === e.target.value);
            handleSelectPhoto(selectedPhoto); // Pasar la foto seleccionada
          }}
          value={selectedPhotoId || ''}
        >
          {photos.map((photo) => (
            <option key={photo.id_foto_s3} value={photo.id_foto_s3}>
              {photo.name}
            </option>
          ))}
        </select>
      </div>

      {/* Mostrar solo la tabla de la foto seleccionada */}
      {selectedPhoto && (
        <table className="photo-table selected-photo">
          <tbody>
            <tr>
              <td><strong>Nombre de Imagen</strong></td>
              <td>{selectedPhoto.name}</td>
            </tr>
            <tr>
              <td><strong>Fecha de Creación</strong></td>
              <td>{selectedPhoto.createdat ? new Date(selectedPhoto.createdat).toLocaleDateString() : 'Desconocido'}</td>
            </tr>
            <tr>
              <td><strong>Último Procesamiento</strong></td>
              <td>{selectedPhoto.updatedat ? new Date(selectedPhoto.updatedat).toLocaleDateString() : 'Desconocido'}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default PhotoGranulometry;
