import React, { useEffect } from 'react';
import './Notification.css';

const Notification = ({ message, duration, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className="notification">
      {message}
    </div>
  );
};

export default Notification;
