import React from 'react';
import './styles/ConfirmDialog.css';

const ConfirmDialog = ({ message, onConfirm, onCancel }) => {
  return (
    <>
      <div className="confirm-dialog-overlay" onClick={onCancel}></div>
      <div className="confirm-dialog">
        <div className="confirm-dialog-content">
          <p>{message}</p>
          <div className="confirm-dialog-buttons">
            <button className="confirm-button" onClick={onConfirm}>SI</button>
            <button className="cancel-button" onClick={onCancel}>NO</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmDialog;
