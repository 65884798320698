import React, { useState, useEffect, useCallback, useContext } from 'react';
import './GalleryView.css';
import Notification from './Notification';
import ConfirmDialog from './ConfirmDialog';
import fetchWithAuth from './fetchWithAuth';
import PhotoList from './PhotoList';
import LotenameTable from './LotenameTable';
import GalleryButtons from './GalleryButtons';
import { GalleryContext } from '../context/GalleryContext';


function Gallery() {
  const { lotenameData, setLotenameData, totalPages, setTotalPages } = useContext(GalleryContext);
  const [photos, setPhotos] = useState([]);
  const [selectedLotename, setSelectedLotename] = useState('');
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBlocking, setIsBlocking] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = useState(null);
  const [isProcessButtonDisabled, setIsProcessButtonDisabled] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ isVisible: false, message: '', onConfirm: null });

  const itemsPerPage = 5;

  // Función para obtener las fotos del lotename
  const fetchAllPhotos = useCallback(async (page = 1) => {
    const timestamp = new Date().getTime();
    const orderby = "Updatedat";  
    const filter = "null";
  
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_DATA_URL}/photos_from_db?page=${page}&limit=${itemsPerPage}&timestamp=${timestamp}&orderby=${orderby}&filter=${filter}`);
  
      if (typeof response === 'string') {
        throw new Error('La respuesta no es un objeto JSON válido');
      }
  
      if (Array.isArray(response.photos)) {
        setLotenameData(response.photos);  // Actualizando el contexto
        setTotalPages(response.totalPages); // Actualizando el contexto
      } else {
        console.error('Los datos recibidos no son un array:', response.photos);
        setPhotos([]);
      }
    } catch (error) {
      console.error('Error al obtener fotos:', error);
    } finally {
      setIsLoading(false);
    }
  }, [itemsPerPage, setLotenameData, setTotalPages]);
  

  const updateProgress = (lotename, processedImages) => {
    const lotenameIndex = lotenameData.findIndex(item => item.lotename === lotename);
    if (lotenameIndex !== -1) {
      const totalPhotos = lotenameData[lotenameIndex].totalPhotos;
      const processedPhotos = processedImages.length;
      const progress = (processedPhotos / totalPhotos) * 100;

      const updatedLotenameData = [...lotenameData];
      updatedLotenameData[lotenameIndex].processedPhotos = processedPhotos;
      updatedLotenameData[lotenameIndex].progress = progress;
      setLotenameData(updatedLotenameData);
    }
  };

  // Cargar fotos cuando la página cambia
  useEffect(() => {
    fetchAllPhotos(currentPage);
  }, [fetchAllPhotos, currentPage]);

  const handleImageProcessed = useCallback((imageName) => {
    const updatedPhotos = photos.map(photo =>
      photo.name === imageName ? { ...photo, status: 'procesada' } : photo
    );
    setPhotos(updatedPhotos);

    const updatedPreviewImages = previewImages.map(image =>
      image.name === imageName ? { ...image, status: 'procesada' } : image
    );
    setPreviewImages(updatedPreviewImages);

    if (selectedLotename) {
      const processedImages = updatedPreviewImages.filter(image => image.status === 'procesada');
      updateProgress(selectedLotename, processedImages);
    }
  }, [photos, previewImages, selectedLotename, lotenameData]);

  // Manejar la selección del lotename
  const handleSelectLotename = async (loteId) => {
    if (isBlocking) return; // No hacer nada si está bloqueado
    
    setIsBlocking(true);
    setIsLoading(true); // Mostrar el spinner de carga
    setSelectedLotename(loteId);
    setPreviewImages([]);
    
    try {
      await loadImagesByLotename(loteId);
    } finally {
      setIsBlocking(false);
      setIsLoading(false); // Ocultar el spinner cuando las fotos se hayan cargado
    }
  };
  

  // Cargar imágenes por lotename
  const loadImagesByLotename = async (loteId) => {
    let filter = null;
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_DATA_URL}/photos-lotename?loteId=${loteId}&filter=${filter}`);
      
      if (Array.isArray(response)) {
        const updatedImages = [];
        
        for (let i = 0; i < response.length; i += 2) {
          const batch = response.slice(i, i + 2); // Selecciona dos imágenes a la vez
          
          const batchPromises = batch.map(async (photo) => {
            // Pasar el loteId como un parámetro en la URL del proxy de imagen
            const imageUrl = `${process.env.REACT_APP_API_FILES_URL}/proxy-image/${photo.id_foto_s3}?loteId=${loteId}`;
            
            // En lugar de utilizar fetchWithAuth aquí, utilizaremos fetch para obtener el blob de la imagen.
            const imageResponse = await fetch(imageUrl);
            const imageBlob = await imageResponse.blob();
            
            // Crear un URL a partir del blob para ser usado en el atributo src de la imagen.
            const imageObjectURL = URL.createObjectURL(imageBlob);
            
            return {
              imageS3Id: photo.id_foto_s3,
              imageUrl: imageObjectURL, // Usa la URL creada a partir del blob.
              name: photo.name,
              status: photo.status
            };
          });
          
          const batchResults = await Promise.all(batchPromises);
          updatedImages.push(...batchResults);
        }
        
        setPreviewImages(updatedImages); // Asignamos las imágenes procesadas en lotes
      } else {
        console.error('Los datos recibidos no son un array:', response);
        setPreviewImages([]);
      }
    } catch (error) {
      console.error('Error al cargar las fotos seleccionadas:', error);
      setPreviewImages([]);
    }
  };

  

  const showNotification = (message) => {
    setNotification({ message, duration: 1000 });
  };

  const handleProcessImages = async () => {
    const imagesToProcess = previewImages.filter(image => image.status !== 'procesada');
    if (imagesToProcess.length === 0) {
      showNotification('No hay imágenes que procesar.');
      return;
    }
  
    console.log("Cuerpo original de imágenes:", imagesToProcess);
  
    // Crear un array de imageS3Id directamente
    const photoIds = imagesToProcess.map(image => image.imageS3Id);
  
    console.log("Cuerpo de imágenes a procesar:", photoIds);
    setIsProcessButtonDisabled(true);
  
    try {
      await fetchWithAuth(`${process.env.REACT_APP_API_FILES_URL}/set-photo-status`, {
        method: 'POST',
        body: JSON.stringify({ PhotoIds: photoIds }), // Cambiar a PhotoIds
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      showNotification('Imágenes enviadas para procesamiento.');
  
      const updatedPhotos = photos.map(photo =>
        photoIds.includes(photo.imageS3Id) 
          ? { ...photo, status: 'procesando' } 
          : photo
      );
      setPhotos(updatedPhotos);
  
      const updatedPreviewImages = previewImages.map(image =>
        photoIds.includes(image.imageS3Id) 
          ? { ...image, status: 'procesando' } 
          : image
      );
      setPreviewImages(updatedPreviewImages);
  
    } catch (error) {
      console.error('Error procesando las imágenes:', error);
      showNotification('Error al procesar las imágenes.');
      setIsProcessButtonDisabled(false);
    }
  };
  


  const getStatusClassName = (status) => {
    switch (status) {
      case 'procesada':
        return 'status-processed';
      case 'subida':
        return 'status-uploaded';
      case 'procesando':
        return 'status-processing';
      default:
        return '';
    }
  };

  const handleDeletePhoto = async (photoId) => {
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/api/drive/file/delete/${photoId}`, {
        method: 'DELETE',
      });

      if (typeof response === 'string') {
        showNotification(response);
      } else {
        showNotification(response.message);
      }

      const updatedPhotos = photos.filter(photo => photo.imageS3Id !== photoId);
      setPhotos(updatedPhotos);
      setPreviewImages(previewImages.filter(image => image.imageS3Id !== photoId));

      if (selectedLotename) {
        updateProgress(selectedLotename, updatedPhotos.filter(photo => photo.status === 'procesada'));
      }
    } catch (error) {
      console.error('Error al eliminar la foto:', error);
      showNotification('Error al eliminar la foto.');
    }
  };

  const confirmDeletePhoto = (photoId, photoName) => {
    setConfirmDialog({
      isVisible: true,
      message: `Desea eliminar la foto: ${photoName}?`,
      onConfirm: () => {
        handleDeletePhoto(photoId);
        setConfirmDialog({ isVisible: false, message: '', onConfirm: null });
      },
      onCancel: () => {
        setConfirmDialog({ isVisible: false, message: '', onConfirm: null });
      }
    });
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
      fetchAllPhotos(nextPage);
    }
  };

  const handlePreviousPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      setCurrentPage(prevPage);
      fetchAllPhotos(prevPage);
    }
  };

  return (
    <div>
      {notification && (
        <Notification
          message={notification.message}
          duration={notification.duration}
          onClose={() => setNotification(null)}
        />
      )}
  
      {confirmDialog.isVisible && (
        <ConfirmDialog
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={confirmDialog.onCancel}
        />
      )}
  
      {/* Mantener el spinner global si las fotos se están cargando */}
      {isLoading && (
        <div className="loading-overlay">
          Cargando fotos...
        </div>
      )}
  
      {/* Contenedor de la tabla de lotenames */}
      <LotenameTable
        lotenameData={lotenameData}
        selectedLotename={selectedLotename}
        handleSelectLotename={handleSelectLotename} 
        currentPage={currentPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
      />
  
      {/* Contenedor de las fotos con spinner específico */}
      <div className="preview-container">
        {isLoading ? (
          <div className="photo-loading-overlay">

          </div>
        ) : (
          previewImages.length > 0 && (
            <PhotoList
              isLoading={isLoading}
              previewImages={previewImages}
              selectedPhotoId={selectedPhotoId}
              handleSelectPhoto={setSelectedPhotoId}
              confirmDeletePhoto={confirmDeletePhoto}
              getStatusClassName={getStatusClassName}
              loteId={selectedLotename} // Pasar el loteId como prop a PhotoList
            />
          )
        )}
      </div>
  
      <GalleryButtons
        handleProcessImages={handleProcessImages}
        isProcessButtonDisabled={isProcessButtonDisabled}
      />
    </div>
  );
  
  
}
export default Gallery;
