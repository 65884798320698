import React, { createContext, useState } from 'react';

export const UploadContext = createContext();

export const UploadProvider = ({ children }) => {
  const [uploads, setUploads] = useState([]);

  const addUpload = (uploadTask) => {
    setUploads((prevUploads) => [...prevUploads, uploadTask]);
  };

  const removeUpload = (uploadTask) => {
    setUploads((prevUploads) => prevUploads.filter((task) => task !== uploadTask));
  };

  return (
    <UploadContext.Provider value={{ addUpload, removeUpload, uploads }}>
      {children}
    </UploadContext.Provider>
  );
};
