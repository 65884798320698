import React, { useState, useEffect } from 'react';
import { apiFetch } from '../http';
import './LoginForm.css';

function LoginForm({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = `${process.env.PUBLIC_URL}/fondo.png`;
    img.onload = () => {
      setIsImageLoaded(true);
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!username.trim() || !password.trim()) {
      setError('Por favor ingresa un usuario y contraseña válidos.');
      return;
    }

    const loginUrl = `${process.env.REACT_APP_API_USER_URL}/login`;

    apiFetch(loginUrl, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(errorData => {
          throw new Error(errorData.message || 'Inicio de sesión fallido. Por favor, verifica tus credenciales.');
        });
      }
      return response.json();
    })
    .then(data => {
      if (data.token) {
        console.log('Login exitoso:', data);
    
        // Guarda el objeto completo del usuario en localStorage
        const user = {
          token: data.token,
          userId: data.userId,
          username: data.username,
          role: data.role,
          email: data.email,  // Si está presente en la respuesta
        };
        
        localStorage.setItem('user', JSON.stringify(user)); // Guarda el usuario completo en localStorage
        onLoginSuccess(user);
      } else {
        setError('Inicio de sesión fallido: credenciales inválidas.');
      }
    })
    
    .catch(error => {
      console.error('Error durante el login:', error);
      setError(error.message);
      setUsername(''); // Limpiar campos después de error
      setPassword('');
    });
  };

  return (
    <div>
      {!isImageLoaded && (
        <div className="loading">
          <div className="spinner"></div>
        </div>
      )}
      <div
        className={`login-container ${isImageLoaded ? 'visible' : 'hidden'}`}
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/fondo.png)` }}
      >
        <div className="tittles">
          <h1 className="login-title"><span className="sigma">Σ</span>FRAG</h1>
          <h2 className="login-subtitle">ROCK FRAGMENTATION ANALYSIS</h2>

          {error && <div className="error-message">{error}</div>}
          <form onSubmit={handleSubmit} className="login-form">
            <div className="input-group-1">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                className="login-input-name"
                required
              />
            </div>
            <div className="input-group-2">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="login-input-pass"
                required
              />
            </div>
            <button type="submit" className="login-button">Login</button>
            <div className="logo-container">
              <a href="https://www.cosmosblasting.com/" target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className="login-logo" />
                <span className="tooltip-text">Acerca de nosotros</span>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
