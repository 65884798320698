import React from 'react';
import './PhotoList.css';

function PhotoList({ isLoading, previewImages, selectedPhotoId, handleSelectPhoto, confirmDeletePhoto, getStatusClassName, loteId }) {
  console.log(previewImages.map(photo => photo.imageS3Id));
  
  const getProxyLink = (photo) => {
    // Asegúrate de incluir el loteId como parámetro en la URL
    return `${process.env.REACT_APP_API_FILES_URL}/proxy-image/${photo.imageS3Id}?loteId=${loteId}`;
  };

  return (
    <div className="preview-container">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="photo-grid">
          {previewImages.map(photo => (
            <div key={photo.imageS3Id} className={`photo-item ${selectedPhotoId === photo.imageS3Id ? 'selected' : ''}`}>
              <img src={getProxyLink(photo)} alt={photo.name} className="preview-image" onClick={() => handleSelectPhoto(photo.imageS3Id)} />
              <div>{photo.name}</div>
              <div className={`status-text ${getStatusClassName(photo.status)}`}>{photo.status}</div>
              <button onClick={() => confirmDeletePhoto(photo.imageS3Id, photo.name)} className="delete-button">Delete</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}


export default PhotoList;


