import React from 'react';
import './GalleryButtons.css';

function GalleryButtons({ handleProcessImages, isProcessButtonDisabled }) {
  return (
    <div className="button-container">
      <button onClick={handleProcessImages} disabled={isProcessButtonDisabled}>PROCESAR IMAGENES</button>
    </div>
  );
}

export default GalleryButtons;
