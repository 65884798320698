import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Asegúrate de importar correctamente jwt-decode
import LoginForm from './components/LoginForm';
import UserView from './components/UserView';
import SuperUserView from './components/SuperUserView';
import PrivateRoute from './components/PrivateRoute';
import Notification from './components/Notification';
import Navbar from './components/Navbar';
import './App.css';

function App() {
  const [user, setUser] = useState(null); // Estado para el usuario autenticado
  const [notification, setNotification] = useState(null); // Estado para la notificación de expiración
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga inicial

  const handleLoginSuccess = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData)); // Guarda todo el objeto user
    console.log('Login successful. UserData:', userData);
  };
  
  
  const handleLogout = useCallback(() => {
    console.log("logout");
    setUser(null);
    localStorage.removeItem('user'); // Elimina solo el objeto 'user'
    window.location.href = '/login'; // Redirige al login
  }, []);
  
  return (
    <Router>
      {!isLoading && user && (
        <Navbar user={user} setUser={setUser} />
      )}
      <AppContent 
        user={user} 
        setUser={setUser} 
        handleLoginSuccess={handleLoginSuccess} 
        handleLogout={handleLogout} 
        notification={notification} 
        setNotification={setNotification}
        isLoading={isLoading}
        setIsLoading={setIsLoading} 
      />
    </Router>
  );
}


function AppContent({ user, setUser, handleLoginSuccess, handleLogout, notification, setNotification, isLoading, setIsLoading }) {

  // Función para verificar el token almacenado en localStorage
  const verifyToken = useCallback(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    
    if (storedUser && storedUser.token) {
      try {
        const decodedToken = jwtDecode(storedUser.token); // Decodificar token JWT
        const currentTime = Date.now() / 1000;
  
        if (decodedToken.exp < currentTime) {
          // Token expirado
          setUser(null);
          localStorage.removeItem('user');
        } else {
          // Token válido
          setUser(storedUser); // Restaurar el usuario completo desde localStorage
          const timeUntilExpiry = (decodedToken.exp * 1000) - Date.now();
          const notificationTime = timeUntilExpiry - (5 * 60 * 1000); // 5 minutos antes de expirar
  
          if (notificationTime > 0) {
            setTimeout(() => {
              setNotification('Tu sesión expirará en 5 minutos. Por favor, guarda tu trabajo.');
            }, notificationTime);
          }
  
          // Programar el logout automático cuando el token expire
          setTimeout(() => {
            handleLogout();
          }, timeUntilExpiry);
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        setUser(null);
        localStorage.removeItem('user');
      }
    } else {
      // No hay token
      setUser(null);
      localStorage.removeItem('user');
    }
    setIsLoading(false); // Marcar que la carga ha terminado
  }, [handleLogout, setUser, setNotification, setIsLoading]);
  
  

  // useEffect que se ejecuta al montar el componente para verificar el token
  useEffect(() => {
    verifyToken(); // Verificar el token al cargar la aplicación
  }, [verifyToken]);
  

  // Mostrar una pantalla de carga mientras verificamos el token
  if (isLoading) {
    return <div>Cargando...</div>;
  }

  console.log('Current user:', user);

  return (
    <div className="App">
      {/* Mostrar Navbar solo si el usuario está autenticado */}
      {!isLoading && user && <Navbar user={user} setUser={setUser} />}

      {/* Mostrar notificación si está presente */}
      {notification && (
        <Notification
          message={notification}
          duration={300000} // Duración de 5 minutos (300000 ms)
          onClose={() => setNotification(null)} // Ocultar notificación al cerrarla
        />
      )}

<Routes>
  <Route
    path="/login"
    element={
      !isLoading && !user ? (
        <LoginForm onLoginSuccess={handleLoginSuccess} /> // Mostrar el formulario de login si no hay usuario
      ) : (
        <Navigate to="/" /> // Redirigir al inicio si ya está autenticado
      )
    }
  />

  <Route
    path="/"
    element={
      !isLoading ? (
        user ? (
          user.role === 'super' ? (
            <SuperUserView />
          ) : (
            <Navigate to="/userview/uploadphoto" />
          )
        ) : (
          <Navigate to="/login" />
        )
      ) : (
        <div>Cargando...</div>
      )
    }
  />

  <Route
    path="/userview/*"
    element={
      !isLoading && user ? (
        <PrivateRoute user={user}>
          <UserView setUser={setUser} />
        </PrivateRoute>
      ) : (
        <Navigate to="/login" />
      )
    }
  />

  <Route
    path="*"
    element={<Navigate to="/" />}
  />
</Routes>


    </div>
  );
}

export default App;
