// src/components/Navbar.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Logout from './Logout';
import './Navbar.css';

function Navbar({ setUser }) {
  const [username, setUsername] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }

    // Event listener para detectar clics fuera del dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (path) => {
    localStorage.setItem('currentPath', path);
    navigate(path);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <span className="sigma">Σ</span>FRAG
      </div>
      <div className="menu-items">
      </div>
      {username && (
        <div className="user-menu" ref={dropdownRef}>
          <div className="welcome-text" onClick={toggleDropdown}>
            Bienvenido, {username} <span className="arrow">&#9662;</span>
          </div>
          {isDropdownOpen && (
            <div className="dropdown">
              <Logout setUser={setUser} />
            </div>
          )}
        </div>
      )}
    </nav>
  );
}

export default Navbar;
