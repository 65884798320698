import React from 'react';
import './Pagination.css';

function Pagination({ currentPage, totalPages, handlePreviousPage, handleNextPage }) {
  return (
    <div className="pagination-buttons">
      <button onClick={handlePreviousPage} disabled={currentPage === 1}>Anterior</button>
      <button onClick={handleNextPage} disabled={currentPage === totalPages}>Siguiente</button>
    </div>
  );
}

export default Pagination;

