// src/components/PhotoTable.js
import React from 'react';
import './PhotoTable.css';

function PhotoTable({ photos, currentPage, itemsPerPage, selectedPhoto, handleSelectPhoto }) {
  return (
    <table className="photoTable">
      <thead>
        <tr>
          <th>N°</th>
          <th>NOMBRE DE IMAGEN</th>
          <th>FECHA DE CREACIÓN</th>
          <th>PUNTUACIÓN</th>
          <th>ÚLTIMO PROCESAMIENTO</th>
          
        </tr>
      </thead>
      <tbody>
        {photos.map((photo, index) => (
          <tr 
            key={photo.id_foto_s3}
            onClick={() => handleSelectPhoto(photo)}
            className={selectedPhoto?.id_foto_s3 === photo.id_foto_s3 ? 'selected-row' : ''}
          >
            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
            <td>{photo.name}</td>
            <td>{new Date(photo.createdat).toLocaleDateString()}</td>
            <td>{photo.rating}</td>
            <td>{new Date(photo.updatedat).toLocaleDateString()}</td>
            
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default PhotoTable;