import React from 'react';
import { useNavigate } from 'react-router-dom';
import fetchWithAuth from './fetchWithAuth'; // Ajusta la ruta según tu estructura de archivos

function Logout({ setUser }) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const logoutUrl = `${process.env.REACT_APP_API_USER_URL}/logout`;

    try {
      // Utiliza fetchWithAuth para hacer la solicitud de logout
      await fetchWithAuth(logoutUrl, {
        method: 'POST'
      });

      // Elimina los datos del almacenamiento local
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      localStorage.removeItem('currentPath');

      // Actualiza el estado del usuario en App.js
      setUser(null);

      // Redirige al usuario a la página de login
      navigate('/login');
    } catch (error) {
      console.error('Error al hacer logout:', error);
    }
  };

  return (
    <button onClick={handleLogout}>Logout</button>
  );
}

export default Logout;

