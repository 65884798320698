// src/components/UserView.js
import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import UploadPhoto from './UploadPhoto';
import Gallery from './Gallery';
import Processing from './Processing';
import Granulometry from './Granulometry';
import './UserView.css';

function UserView({ user, setUser }) { 
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = localStorage.getItem('currentPath');
    if (currentPath && currentPath !== location.pathname) {
      navigate(currentPath);
    }
  }, [location.pathname, navigate]);

  const handleUploadSuccess = (uploadResults) => {
    console.log('handleUploadSuccess called', uploadResults);
    navigate('/userview/gallery');
  };

  return (
    <div className="userViewLayout">
      <Navbar user={user} setUser={setUser} />
      <div className="mainContent">
        <Routes>
          <Route path="uploadphoto" element={<UploadPhoto onUploadSuccess={handleUploadSuccess} />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="processing" element={<Processing />} />
          <Route path="granulometry" element={<Granulometry />} />
          <Route path="*" element={<UploadPhoto onUploadSuccess={handleUploadSuccess} />} />
        </Routes>
      </div>
    </div>
  );
}

export default UserView;

