import React, { useEffect, useState } from 'react';
import './TablaUser.css';
import UserPhotosTable from './UserPhotosTable';

const TablaUser = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUsername, setSelectedUsername] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/super/users`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Asumiendo que usas token de autenticación
          }
        });
        const data = await response.json();

        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          console.error('Los datos recibidos no son un array:', data);
          setUsers([]);
        }
      } catch (error) {
        console.error('Error al obtener los usuarios:', error);
        setUsers([]);
      }
    };

    fetchUsers();
  }, []);

  const handleUserSelect = (userId, username) => {
    setSelectedUserId(userId);
    setSelectedUsername(username);
  };

  return (
    <div className="table-container">
      <h2>Lista de Usuarios</h2>
      <table>
        <thead>
          <tr>
            <th>Nombre de Usuario</th>
            <th>Fecha de Creación</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(users) ? (
            users.map(user => (
              <tr key={user._id} onClick={() => handleUserSelect(user._id, user.username)}>
                <td>{user.username}</td>
                <td>{new Date(user.createdAt).toLocaleDateString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No hay usuarios disponibles</td>
            </tr>
          )}
        </tbody>
      </table>
      {selectedUserId && <UserPhotosTable userId={selectedUserId} username={selectedUsername} />}
    </div>
  );
};

export default TablaUser;
