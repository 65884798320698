import React from 'react';
import './PreviewList.css';

function PreviewList({ selectedFiles, handleRemoveFile, uploadProgress, isUploading }) {
  return (
    <div className="preview-list">
      {selectedFiles.map((file, index) => (
        <div key={index} className="preview-item">
          <img src={file.preview} alt={`preview ${index}`} />
          <button onClick={() => handleRemoveFile(index)} disabled={isUploading}>Remove</button>
          {uploadProgress[file.index] !== undefined && (
            <div className="progress-container">
              <div className="progress-bar">
                <div className="progress" style={{ width: `${uploadProgress[file.index]}%` }}></div>
              </div>
              <span className="progress-text">{uploadProgress[file.index]}%</span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default PreviewList;

