import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Asegúrate de importar correctamente jwt-decode
import Logout from './Logout';
import './Navbar.css';

function Navbar({ setUser }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [decodedUser, setDecodedUser] = useState(null); // Estado para almacenar el usuario decodificado
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  // Decodificar el token si existe en localStorage
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    console.log('storedUser:', storedUser);

    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);

      if (parsedUser.token) {
        try {
          const decoded = jwtDecode(parsedUser.token);
          setDecodedUser(decoded); // Almacena los datos decodificados
          console.log('User decodificado:', decoded);
        } catch (error) {
          console.error('Error al decodificar el token:', error);
        }
      }
    } else {
      console.log('No user found in localStorage');
    }
  }, []);
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    console.log('storedUser:', storedUser);
  
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
  
      if (parsedUser.token) {
        try {
          const decoded = jwtDecode(parsedUser.token);
          setDecodedUser(decoded); // Almacena los datos decodificados
          localStorage.setItem('username', decoded.sub); // Guardar el nombre de usuario en localStorage
          console.log('User decodificado:', decoded);
        } catch (error) {
          console.error('Error al decodificar el token:', error);
        }
      }
    } else {
      console.log('No user found in localStorage');
    }
  }, []);
  
  // Event listener para detectar clics fuera del dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const menuItems = [
    { path: '/userview/uploadphoto', label: 'SUBIR IMÁGENES' },
    { path: '/userview/gallery', label: 'STATUS' },
    { path: '/userview/processing', label: 'PROCESADAS' },
    { path: '/userview/granulometry', label: 'GRANULOMETRÍA' }
  ];

  const handleItemClick = (path) => {
    localStorage.setItem('currentPath', path);
    navigate(path);
  };

  const handleAbout = () => {
    const url = "https://www.cosmosblasting.com/";
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <span className="sigma">Σ</span>FRAG
      </div>
      <div className="menu-items">
        {menuItems.map(item => (
          <div
            key={item.path}
            className={`navbarItem ${location.pathname === item.path ? 'active' : ''}`}
            onClick={() => handleItemClick(item.path)}
          >
            {item.label}
          </div>
        ))}
      </div>
      {/* Mostrar el menú de usuario solo si el token está decodificado */}
      {decodedUser && (
        <div className="user-menu" ref={dropdownRef}>
          <div className="welcome-text" onClick={toggleDropdown}>
            Bienvenido, {decodedUser.sub} <span className="arrow">&#9662;</span>
          </div>
          {isDropdownOpen && (
            <div className="dropdown">
              <button onClick={handleAbout}>About Cosmos Blasting</button>
              <Logout setUser={setUser} />
            </div>
          )}
        </div>
      )}
    </nav>
  );
}

export default Navbar;
