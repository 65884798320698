import React from 'react';
import './FileInput.css';

function FileInput({ onFileChange, disabled }) {
  return (
    <div className="custom-file-input">
      <input
        type="file"
        accept=".png,.jpg,.jpeg"
        multiple
        onChange={onFileChange}
        disabled={disabled}
        id="fileInput"
        style={{ display: 'none' }}
      />
      <label htmlFor="fileInput" className="file-input-label">
        ELEGIR IMÁGENES
      </label>
    </div>
  );
}

export default FileInput;
