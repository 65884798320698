import React from 'react';
import './LotenameTable.css';

function LotenameTable({ lotenameData, selectedLotename, handleSelectLotename, currentPage, totalPages, itemsPerPage, handleNextPage, handlePreviousPage }) {
  console.log(lotenameData)
  return (
    <div className="lotename-table-container">
      <table className="lotename-table">
        <thead>
          <tr>
            <th>N°</th>
            <th>UUID</th>
            <th>LOTE</th>
            <th>TOTAL IMÁGENES</th>
            <th>IMÁGENES PROCESADAS</th>
            <th>DIAMETRO(cm)</th>
            <th>PROGRESO</th>
          </tr>
        </thead>
        <tbody>
          {lotenameData.map((data, index) => (
            <tr 
              key={data.loteId} 
              onClick={() => handleSelectLotename(data.loteId, data.diameter)} // Pasando lotename y diameter
              className={selectedLotename === data.loteId ? 'selected-row' : ''}
            >
              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
              <td>{data.loteId}</td>
              <td>{data.lotename}</td>
              <td>{data.totalPhotos}</td>
              <td>{data.processedPhotos}</td>
              <td>{data.diameter}</td>
              <td>
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${data.progress.toFixed(2)}%` }}>
                    <span>{data.progress.toFixed(2)}%</span>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-controls">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Anterior
        </button>
        <span>Página {currentPage} de {totalPages}</span> {/* Muestra el número total de páginas */}
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Siguiente
        </button>
      </div>
    </div>
  );
}

export default LotenameTable;
