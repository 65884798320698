import React, { useState } from 'react';
import './UploadForm.css';

function UploadForm({ lotename, setLotename, diameter, setDiameter, handleUploadAll, isUploading, setAdditionalData }) {
  const [burden, setBurden] = useState('');
  const [spacing, setSpacing] = useState('');
  const [rockDensity, setRockDensity] = useState('');
  const [vpRock, setVpRock] = useState('');
  const [explosiveDensity, setExplosiveDensity] = useState('');
  const [detonationVelocity, setDetonationVelocity] = useState('');
  const [powerFactor, setPowerFactor] = useState('');

  const handleSubmit = () => {
    const additionalData = {
      burden: burden || '',
      spacing: spacing || '',
      rockDensity: rockDensity || '',
      vpRock: vpRock || '',
      explosiveDensity: explosiveDensity || '',
      detonationVelocity: detonationVelocity || '',
      powerFactor: powerFactor || '',
    };
  
    setAdditionalData(additionalData);  // Actualizamos el estado
    handleUploadAll(additionalData);  // Pasamos los datos directamente a la función
  };
  

  return (
    <div className="uploadForm">
      <h2>ROCK PARAMETERS</h2>
      <div className="section-group">
        <div className="input-group">
          <input
            type="text"
            value={rockDensity}
            onChange={(e) => setRockDensity(e.target.value)}
            placeholder="Densidad roca (g/cm³)"
            disabled={isUploading}
          />
          <input
            type="text"
            value={vpRock}
            onChange={(e) => setVpRock(e.target.value)}
            placeholder="Velocidad Vp roca (m/s)"
            disabled={isUploading}
          />
        </div>
      </div>

      <h2>EXPLOSIVE PARAMETERS</h2>
      <div className="section-group">
        <div className="input-group">
          <input
            type="text"
            value={explosiveDensity}
            onChange={(e) => setExplosiveDensity(e.target.value)}
            placeholder="Densidad explosivo (g/cm³)"
            disabled={isUploading}
          />
          <input
            type="text"
            value={detonationVelocity}
            onChange={(e) => setDetonationVelocity(e.target.value)}
            placeholder="Velocidad detonación (m/s)"
            disabled={isUploading}
          />
        </div>
      </div>

      <h2>DESIGN</h2>
      <div className="section-group">
        <div className="input-group">
          <input
            type="text"
            value={burden}
            onChange={(e) => setBurden(e.target.value)}
            placeholder="Burden (m)"
            disabled={isUploading}
          />
          <input
            type="text"
            value={spacing}
            onChange={(e) => setSpacing(e.target.value)}
            placeholder="Espaciamiento (m)"
            disabled={isUploading}
          />
        </div>
        <input
          type="text"
          value={powerFactor}
          onChange={(e) => setPowerFactor(e.target.value)}
          placeholder="Factor de potencia (kg/tm)"
          disabled={isUploading}
        />
      </div>

      <h2>FRAGMENT ANALYSYS</h2>
      <div className="section-group">
        <div className="input-group">
          <input
            type="text"
            value={lotename}
            onChange={(e) => setLotename(e.target.value)}
            placeholder="Lotename"
            disabled={isUploading}
          />
          <input
            type="text"
            value={diameter}
            onChange={(e) => setDiameter(e.target.value)}
            placeholder="SCALE (cm)"
            disabled={isUploading}
          />
        </div>
      </div>

      <button className="buttonupload" onClick={handleSubmit} disabled={isUploading}>
        ENVIAR DATOS
      </button>
    </div>
  );
}

export default UploadForm;

