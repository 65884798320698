// src/context/GalleryContext.js
import React, { createContext, useState } from 'react';

export const GalleryContext = createContext();

export const GalleryProvider = ({ children }) => {
  const [lotenameData, setLotenameData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  return (
    <GalleryContext.Provider value={{ lotenameData, setLotenameData, totalPages, setTotalPages }}>
      {children}
    </GalleryContext.Provider>
  );
};

// Asegúrate de que estás exportando correctamente
export default GalleryProvider;
