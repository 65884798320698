import React, { useState, useContext } from 'react';
import axios from 'axios';
import { UploadContext } from '../context/UploadContext';
import FileInput from './FileInput';
import PreviewList from './PreviewList';
import UploadForm from './UploadForm';
import PlaceholderView from './PlaceholderView';
import Notification from './Notification';
import './UploadPhoto.css';

function UploadPhoto({ onUploadSuccess }) {
  const { addUpload, removeUpload } = useContext(UploadContext);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [lotename, setLotename] = useState('');
  const [diameter, setDiameter] = useState('');
  const [uploadProgress, setUploadProgress] = useState({});
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [notification, setNotification] = useState(null);
  const [loteId, setLoteId] = useState(null);
  const [additionalData, setAdditionalData] = useState({
    burden: '',
    spacing: '',
    rockDensity: '',
    vpRock: '',
    explosiveDensity: '',
    detonationVelocity: '',
    powerFactor: ''
  });
  const [isUploading, setIsUploading] = useState(false); // Nuevo estado

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).slice(0, 10 - selectedFiles.length);
    const newFiles = files.map((file, index) => ({
      file,
      preview: URL.createObjectURL(file),
      index: selectedFiles.length + index + 1,
      progress: 0,
    }));
    setSelectedFiles((prev) => [...prev, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const createLoteData = async (additionalData) => {
    const storedUser = localStorage.getItem('user');
    const token = storedUser ? JSON.parse(storedUser).token : null;
  
    const data = {
      lotename,
      diameter,
      burden: additionalData.burden || null,
      spacing: additionalData.spacing || null,
      rockDensity: additionalData.rockDensity || null,
      vpRock: additionalData.vpRock || null,
      explosiveDensity: additionalData.explosiveDensity || null,
      detonationVelocity: additionalData.detonationVelocity || null,
      powerFactor: additionalData.powerFactor || null,
    };
  
    console.log("data enviada", data);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_FILES_URL}/create-lote`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        setLoteId(response.data.lote_id);
        setLotename(response.data.lotename);
        return response.data.lote_id;
      } else {
        throw new Error('Failed to create lote data');
      }
    } catch (error) {
      console.error('Error creating lote data:', error);
      setError('Error creating lote data');
      throw error;
    }
  };
  

  const uploadFile = async (fileObj, loteId) => {
    const formData = new FormData();
    formData.append('file', fileObj.file);
    formData.append('name', fileObj.file.name);  
    formData.append('loteId', loteId);
    formData.append('lotename', lotename);
    formData.append('fileIndex', fileObj.index);

    const storedUser = localStorage.getItem('user');
    const token = storedUser ? JSON.parse(storedUser).token : null;

    const uploadTask = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_FILES_URL}/upload-photo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (event) => {
            if (event.lengthComputable) {
              const progress = (event.loaded / event.total) * 100;
              setUploadProgress((prevProgress) => ({
                ...prevProgress,
                [fileObj.index]: progress,
              }));
            }
          },
        });

        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [fileObj.index]: 100,
        }));

        if (onUploadSuccess) {
          onUploadSuccess();
        }
      } catch (error) {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [fileObj.index]: 'Failed',
        }));
        setNotification(`Error uploading file: ${fileObj.file.name}`);
        console.error('Error uploading file:', error);
      } finally {
        removeUpload(uploadTask);
      }
    };

    addUpload(uploadTask);
    uploadTask();
  };

  const handleUploadAll = async (additionalData) => {
    if (!lotename.trim() || isNaN(parseFloat(diameter))) {
      alert('Por favor, ingrese valores válidos para lotename y diameter.');
      return;
    }
  
    try {
      setIsUploading(true); // Iniciamos la carga
      const loteId = await createLoteData(additionalData);
  
      for (const fileObj of selectedFiles) {
        uploadFile(fileObj, loteId);
      }
  
      setSuccess('Subida en progreso!');
    } catch (uploadError) {
      console.error('Upload failed:', uploadError);
      setError('Upload failed, please try again.');
      setSuccess('');
    } finally {
      setIsUploading(false); // Finalizamos la carga
    }
  };
  

  const closeNotification = () => {
    setNotification(null);
  };

  return (
    <div className="upload-photo-container">
      <div className="tools-section">
        <FileInput onFileChange={handleFileChange} disabled={selectedFiles.length >= 10} />
        <span className="file-selected-text">
          {selectedFiles.length === 0 ? 'Ninguna imagen seleccionada' : `${selectedFiles.length} imágene(s) seleccionada(s)`}
        </span>
        <UploadForm
          lotename={lotename}
          setLotename={setLotename}
          diameter={diameter}
          setDiameter={setDiameter}
          handleUploadAll={handleUploadAll}
          isUploading={isUploading} // Pasamos isUploading
          setAdditionalData={setAdditionalData} // Pasamos setAdditionalData
        />
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
      </div>
      <div className="preview-section">
        {selectedFiles.length === 0 ? (
          <PlaceholderView />
        ) : (
          <PreviewList
            selectedFiles={selectedFiles}
            handleRemoveFile={handleRemoveFile}
            uploadProgress={uploadProgress}
          />
        )}
      </div>
      {notification && (
        <Notification message={notification} duration={3000} onClose={closeNotification} />
      )}
    </div>
  );
}

export default UploadPhoto;
