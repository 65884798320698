// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { UploadProvider } from './context/UploadContext';
import { GalleryProvider } from './context/GalleryContext';
import { ProcessingProvider } from './context/ProcessingContext';
import { GranulometryProvider } from './context/GranulometryContext';


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <UploadProvider>
      <GalleryProvider>
        <ProcessingProvider>
          <GranulometryProvider>
            <App />
          </GranulometryProvider>
        </ProcessingProvider>
      </GalleryProvider>
    </UploadProvider>
  </React.StrictMode>
);
