import React from 'react';
import Navbar from './NavbarSuper';
import TablaUser from './TablaUser';

const SuperUserView = ({ setUser }) => {
  return (
    <div>
      <Navbar setUser={setUser} />
      <TablaUser />
    </div>
  );
};

export default SuperUserView;
