import React, { useEffect, useState } from 'react';

const UserPhotosTable = ({ userId, username }) => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (userId) {
      const fetchPhotos = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/super/photos/${userId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          const data = await response.json();
          setPhotos(data);
        } catch (error) {
          console.error('Error al obtener las fotos del usuario:', error);
        }
      };

      fetchPhotos();
    }
  }, [userId]);

  const deletePhoto = async (photoName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/super/photos`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ photoName, username })
      });

      if (response.ok) {
        setPhotos(photos.filter(photo => photo.name !== photoName));
      } else {
        console.error('Error al eliminar la foto:', await response.text());
      }
    } catch (error) {
      console.error('Error al eliminar la foto:', error);
    }
  };

  return (
    <div>
      <h2>Fotos del Usuario</h2>
      <table>
        <thead>
          <tr>
            <th>Nombre de Foto</th>
            <th>Fecha de Creación</th>
            <th>Nombre del Lote</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {photos.map(photo => (
            <tr key={photo._id}>
              <td>{photo.name}</td>
              <td>{new Date(photo.createdAt).toLocaleDateString()}</td>
              <td>{photo.lotename}</td>
              <td>{photo.status}</td>
              <td>
                <button onClick={() => deletePhoto(photo.name)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserPhotosTable;
