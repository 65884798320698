import React, { useEffect, useState, useContext, useCallback } from 'react';
import './ProcessedPhotoView.css';
import Pagination from './Pagination';
import PhotoTable from './PhotoTable';
import Notification from './Notification';
import LotenameTable from './LotenameTable'; 
import { ProcessingContext } from '../context/ProcessingContext';
import fetchWithAuth from './fetchWithAuth';

const Processing = () => {
  const { fetchAllPhotos } = useContext(ProcessingContext);
  const [lotenameData, setLotenameData] = useState([]);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [totalPagesLotename, setTotalPagesLotename] = useState(0);
  const [selectedLotename, setSelectedLotename] = useState('');
  const [selectedDiameter, setSelectedDiameter] = useState(null);

  const [photos, setPhotos] = useState([]);  
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPagesPhotos, setTotalPagesPhotos] = useState(0);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [originalImageData, setOriginalImageData] = useState('');
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [notification, setNotification] = useState(null);
  const [rating, setRating] = useState(0);

  const itemsPerPage = 5;

  // Maneja la selección de un lote
  const handleSelectLotename = useCallback(async (loteId, diameter) => {
    setIsLoadingPhotos(true);
    setSelectedLotename(loteId);
    setSelectedDiameter(diameter);
    setCurrentPage2(1); // Resetear la paginación de fotos al seleccionar un nuevo lote

    const filter = "procesada";
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_DATA_URL}/photos_process?loteId=${loteId}&filter=${filter}`);
      const photosData = Array.isArray(response) ? response : [];
      setPhotos(photosData);
      setTotalPagesPhotos(Math.ceil(photosData.length / itemsPerPage));
    } catch (error) {
      console.error('Error al obtener las fotos:', error);
      setNotification('Error al obtener las fotos.');
    } finally {
      setIsLoadingPhotos(false);
    }
  }, [itemsPerPage]);

  // Obtener los datos de los lotes desde el contexto
  useEffect(() => {
    const fetchLotenameData = async () => {
      try {
        await fetchAllPhotos(currentPage1, setLotenameData, setTotalPagesLotename, itemsPerPage);
      } catch (error) {
        console.error('Error al obtener los lotes:', error);
        setNotification('Error al obtener los lotes.');
      }
    };

    fetchLotenameData();
  }, [fetchAllPhotos, currentPage1, itemsPerPage]);

  // Funciones de paginación para la primera tabla
  const handleNextPage1 = useCallback(() => {
    setCurrentPage1(prevPage => Math.min(prevPage + 1, totalPagesLotename));
  }, [totalPagesLotename]);

  const handlePreviousPage1 = useCallback(() => {
    setCurrentPage1(prevPage => Math.max(prevPage - 1, 1));
  }, []);

  // Funciones de paginación para la segunda tabla
  const handleNextPage2 = useCallback(() => {
    setCurrentPage2(prevPage => Math.min(prevPage + 1, totalPagesPhotos));
  }, [totalPagesPhotos]);

  const handlePreviousPage2 = useCallback(() => {
    setCurrentPage2(prevPage => Math.max(prevPage - 1, 1));
  }, []);

  // Maneja la selección de una foto
  const handleSelectPhoto = useCallback(async (photo) => {
    if (!photo || !photo.id_foto_s3) {
      setNotification('No se pudo seleccionar la foto correctamente.');
      return;
    }

    if (!selectedLotename) {
      setNotification('Lote no seleccionado.');
      return;
    }

    setIsLoadingImage(true);
    setSelectedPhoto(photo);
    setOriginalImageData('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_DATA_URL}/photoresult/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_foto_s3: photo.id_foto_s3,
          loteId: selectedLotename,
          type: "tamizaje",
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }

      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setOriginalImageData(imageUrl);
    } catch (error) {
      console.error('Error al descargar la imagen desde la API:', error);
      setNotification('Error al descargar la imagen desde la API.');
    } finally {
      setIsLoadingImage(false);
    }
  }, [selectedLotename]);

  // Limpiar la URL de la imagen cuando el componente se desmonta o cambia la imagen
  useEffect(() => {
    return () => {
      if (originalImageData) {
        URL.revokeObjectURL(originalImageData);
      }
    };
  }, [originalImageData]);

  // Maneja el cambio de calificación
  const handleRatingChange = useCallback((ratingValue) => {
    setRating(ratingValue);
  }, []);

  // Maneja el envío de la calificación
  const handleSubmit = useCallback(async () => {
    if (!selectedPhoto) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_STATSYREPORT_URL}/setrating`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ photoId: selectedPhoto._id, rating }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit rating');
      }

      setNotification('Puntuación Enviada!');
      setRating(0); // Resetear la calificación después del envío
    } catch (error) {
      console.error('Error al enviar la calificación:', error);
      setNotification('Error al enviar la calificación.');
    }
  }, [selectedPhoto, rating]);

  // Datos paginados para las fotos
  const paginatedPhotos = photos.slice((currentPage2 - 1) * itemsPerPage, currentPage2 * itemsPerPage);

  return (
    <div className="processedPhotoContainer">
      {notification && (
        <Notification
          message={notification}
          duration={3000}
          onClose={() => setNotification(null)}
        />
      )}

      {/* Primera Tabla de Lotename */}
      <div className="photoTableContainer">
        <LotenameTable
          lotenameData={lotenameData}
          selectedLotename={selectedLotename}
          handleSelectLotename={handleSelectLotename}
          currentPage={currentPage1}
          totalPages={totalPagesLotename}
          itemsPerPage={itemsPerPage}
          handleNextPage={handleNextPage1}
          handlePreviousPage={handlePreviousPage1}
        />
      </div>

      {/* Tabla de Fotos */}
      <div className="photoTableContainer">
        {isLoadingPhotos ? (
          <div className="spinner"></div>
        ) : (
          <PhotoTable
            photos={paginatedPhotos}
            currentPage={currentPage2}
            itemsPerPage={itemsPerPage}
            selectedPhoto={selectedPhoto}
            handleSelectPhoto={!isLoadingImage ? handleSelectPhoto : () => {}}
          />
        )}
        <Pagination
          currentPage={currentPage2}
          totalPages={totalPagesPhotos}
          handlePreviousPage={handlePreviousPage2}
          handleNextPage={handleNextPage2}
        />
      </div>

      {/* Sección de Imagen y Feedback */}
      {isLoadingImage ? (
        <div className="spinner"></div>
      ) : (
        selectedPhoto && originalImageData && (
          <div className="imageSection">
            <div className="imageContainer">
              <img src={originalImageData} alt="Processed" className="processedImage" />
            </div>
            <div className="feedbackSection">
              <h2 className="feedbackTitle">Proporcione su feedback, puntuando la segmentación</h2>
              <div className="ratingContainer">
                {[1, 2, 3, 4, 5].map((value) => (
                  <button
                    key={value}
                    className={`ratingButton ${rating === value ? 'selected' : ''}`}
                    onClick={() => handleRatingChange(value)}
                    aria-label={`Calificar con ${value} estrellas`}
                  >
                    <span className="emoji">
                      {value === 1 ? '😞' : value === 2 ? '☹️' : value === 3 ? '🙂' : value === 4 ? '😊' : '🤩'}
                    </span>
                  </button>
                ))}
                <button 
                  className="submitButton" 
                  onClick={handleSubmit}
                  disabled={rating === 0}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Processing;

