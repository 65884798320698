import React, { createContext, useState, useCallback } from 'react';
import fetchWithAuth from '../components/fetchWithAuth'; // Ajusta la ruta según sea necesario

// Crea el contexto
export const GranulometryContext = createContext();

// Define el proveedor del contexto
export const GranulometryProvider = ({ children }) => {
  const [lotenameData, setLotenameData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 5;

  const fetchAllPhotos = useCallback(async (page = 1) => {
    const timestamp = new Date().getTime();
    const orderby = "Updatedat";
    const filter = "procesada";

    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_DATA_URL}/photos_from_db?page=${page}&limit=${itemsPerPage}&timestamp=${timestamp}&orderby=${orderby}&filter=${filter}`);
      if (response && Array.isArray(response.photos)) {
        setLotenameData(response.photos);
        setTotalPages(response.totalPages);
      } else {
        setLotenameData([]);
        setTotalPages(0);
      }
    } catch (error) {
      console.error('Error al obtener lotenames:', error);
      // Manejar el error aquí (puedes agregar un estado de error si lo deseas)
    }
  }, [itemsPerPage]);

  return (
    <GranulometryContext.Provider value={{ lotenameData, setLotenameData, currentPage, setCurrentPage, totalPages, setTotalPages, fetchAllPhotos }}>
      {children}
    </GranulometryContext.Provider>
  );
};
