import React from 'react';
import './LoteTable.css';

function LoteTable({ loteData }) {
  return (
    <div className="lotename-table-container">
      <h2 className="lote-title">INFORMACIÓN DEL LOTENAME</h2>
      {loteData.map((data) => (
        <table key={data.id} className="lote-table">
          <tbody>
            <tr>
              <td><strong>UUID</strong></td>
              <td>{data.id}</td>
            </tr>
            <tr>
              <td><strong>Lote</strong></td>
              <td>{data.lotename}</td>
            </tr>
            <tr>
              <td><strong>Burden</strong></td>
              <td>{data.burden}</td>
            </tr>
            <tr>
              <td><strong>Spacing</strong></td>
              <td>{data.spacing}</td>
            </tr>
            <tr>
              <td><strong>Rock Density</strong></td>
              <td>{data.rockdensity}</td>
            </tr>
            <tr>
              <td><strong>VP Rock</strong></td>
              <td>{data.vprock}</td>
            </tr>
            <tr>
              <td><strong>Explosive Density</strong></td>
              <td>{data.explosivedensity}</td>
            </tr>
            <tr>
              <td><strong>Detonation Velocity</strong></td>
              <td>{data.detonationvelocity}</td>
            </tr>
            <tr>
              <td><strong>Power Factor</strong></td>
              <td>{data.powerfactor}</td>
            </tr>
            <tr>
              <td><strong>Diameter</strong></td>
              <td>{data.diameter}</td>
            </tr>
            <tr>
              <td><strong>Fecha de Creación</strong></td>
              <td>{new Date(data.createdat).toLocaleDateString()}</td>
            </tr>
            <tr>
              <td><strong>Último Procesamiento</strong></td>
              <td>{new Date(data.updatedat).toLocaleDateString()}</td>
            </tr>
            {/* Puedes añadir más filas aquí si tienes más campos */}
          </tbody>
        </table>
      ))}
    </div>
  );
}

export default LoteTable;
