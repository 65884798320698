import React, { createContext, useState, useCallback } from 'react';
import fetchWithAuth from '../components/fetchWithAuth'; // Asegúrate de ajustar la ruta correctamente

export const ProcessingContext = createContext();

export const ProcessingProvider = ({ children }) => {
  const [processingData, setProcessingData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const fetchAllPhotos = useCallback(async (page = 1, setLotenameData1, setTotalPagesLotename1, itemsPerPage) => {
    const timestamp = new Date().getTime();
    const orderby = "Updatedat";
    const filter = "procesada";  // Cambia a "null" si no necesitas filtrar

    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_DATA_URL}/photos_from_db?page=${page}&limit=${itemsPerPage}&timestamp=${timestamp}&orderby=${orderby}&filter=${filter}`);
      if (Array.isArray(response.photos)) {
        setLotenameData1(response.photos);  // Establecer los datos en la primera tabla
        setTotalPagesLotename1(response.totalPages);  // Total de páginas
      } else {
        setLotenameData1([]);
      }
    } catch (error) {
      console.error('Error al obtener lotenames:', error);
    }
  }, []);

  return (
    <ProcessingContext.Provider value={{ processingData, setProcessingData, isProcessing, setIsProcessing, fetchAllPhotos }}>
      {children}
    </ProcessingContext.Provider>
  );
};
