import React, { useState, useEffect, useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './PlaceholderView.css';

Chart.register(ChartDataLabels);

function PlaceholderView() {
  const [photos, setPhotos] = useState(null);
  const [ratings, setRatings] = useState(null);
  const [userStats, setUserStats] = useState(null);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(true);
  const [isLoadingRatings, setIsLoadingRatings] = useState(true);
  const [isLoadingUserStats, setIsLoadingUserStats] = useState(true);
  const [error, setError] = useState(null);

  const fetchPhotoStats = useCallback(async () => {
    setIsLoadingPhotos(true);
    setError(null);

    try {
      const userName = localStorage.getItem('username');

      const response = await fetch(`${process.env.REACT_APP_API_STATSYREPORT_URL}/photoStats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Username: userName }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log(data, "data fotos");
      if (data) {
        localStorage.setItem('photoStats', JSON.stringify(data));
        setPhotos(data);
      } else {
        setPhotos(null);
        setError('Error al obtener las estadísticas de fotos: formato inesperado');
      }
    } catch (error) {
      console.error('Error al obtener las fotos:', error);
      setError('Error al obtener las fotos');
    } finally {
      setIsLoadingPhotos(false);
    }
  }, []);

  const fetchRatingStats = useCallback(async () => {
    setIsLoadingRatings(true);
    setError(null);

    try {
      const userName = localStorage.getItem('username');

      const response = await fetch(`${process.env.REACT_APP_API_STATSYREPORT_URL}/ratingStats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Username: userName }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log(data, "data ratings");
      if (data) {
        localStorage.setItem('ratingStats', JSON.stringify(data));
        setRatings(data);
      } else {
        setRatings(null);
        setError('Error al obtener las estadísticas de calificaciones: formato inesperado');
      }
    } catch (error) {
      console.error('Error al obtener las calificaciones:', error);
      setError('Error al obtener las calificaciones');
    } finally {
      setIsLoadingRatings(false);
    }
  }, []);

  const fetchUserStats = useCallback(async () => {
    setIsLoadingUserStats(true);
    setError(null);

    try {
      const userName = localStorage.getItem('username');

      const response = await fetch(`${process.env.REACT_APP_API_STATSYREPORT_URL}/userStats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Username: userName }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log(data, "data user stats");
      if (data) {
        setUserStats(data);
      } else {
        setUserStats(null);
        setError('Error al obtener las estadísticas de usuario.');
      }
    } catch (error) {
      console.error('Error al obtener las estadísticas de usuario:', error);
      setError('Error al obtener las estadísticas de usuario');
    } finally {
      setIsLoadingUserStats(false);
    }
  }, []);

  useEffect(() => {
    const initialFetch = async () => {
      await fetchPhotoStats();
      await fetchRatingStats();
      await fetchUserStats();

      const interval = setInterval(() => {
        const savedPhotoStats = localStorage.getItem('photoStats');
        const savedRatingStats = localStorage.getItem('ratingStats');
        if (savedPhotoStats) {
          setPhotos(JSON.parse(savedPhotoStats));
        }
        if (savedRatingStats) {
          setRatings(JSON.parse(savedRatingStats));
        }
        fetchPhotoStats();
        fetchRatingStats();
        fetchUserStats();
      }, 10 * 60 * 1000);

      return () => clearInterval(interval);
    };

    initialFetch();
  }, [fetchPhotoStats, fetchRatingStats, fetchUserStats]);

  if (isLoadingPhotos || isLoadingRatings || isLoadingUserStats) {
    return <div>Cargando estadísticas...</div>;
  }

  if (error) {
    return <div>{"Trabajando en actualizaciones..."}</div>;
  }

  if (!photos || !ratings || !userStats) {
    return (
      <div className="placeholder-view">
        <h2>No hay datos disponibles</h2>
        <p>Por favor, seleccione algunas imágenes para subir o calificar.</p>
      </div>
    );
  }

  const data1 = {
    labels: ['TOTAL', 'SUBIDAS', 'PROCESADAS', 'PROCESANDO'],
    datasets: [
      {
        data: [
          photos.totalphotos,
          photos.totalphotosupload,
          photos.totalphotosprocessed,
          photos.totalphotosprocessing,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options1 = {
    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
          },
        },
        title: {
          display: true,
          text: 'Imágenes',
          font: {
            size: 16,
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 14,
          },
        },
        title: {
          display: true,
          text: 'Cantidad',
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'STATUS DE LAS IMÁGENES',
        font: {
          size: 18,
        },
      },
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        formatter: (value) => value,
        color: 'black',
        font: {
          size: 14,
          weight: 'bold',
        },
      },
    },
  };

  const data2 = {
    labels: ['😞', '☹️', '🙂', '😊', '🤩'],
    datasets: [
      {
        data: [
          ratings.totalratings0,
          ratings.totalratings1,
          ratings.totalratings2,
          ratings.totalratings3,
          ratings.totalratings4,
          ratings.totalratings5,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options2 = {
    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
          },
        },
        title: {
          display: true,
          text: 'Puntuaciones',
          font: {
            size: 16,
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 14,
          },
        },
        title: {
          display: true,
          text: 'Cantidad',
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'CALIFICACIONES DE LAS IMÁGENES',
        font: {
          size: 18,
        },
      },
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        formatter: (value) => value,
        color: 'black',
        font: {
          size: 14,
          weight: 'bold',
        },
      },
    },
  };

 // **Nuevo código para ajustar la unidad de espacio usado**

  // Inicializamos variables para el espacio usado y su label
  let espacioUsado = userStats.espaciousadogb;
  let labelEspacio = 'Espacio Usado (GB)';

  // Verificamos si el espacio usado es menor a 1 GB
  if (espacioUsado < 1) {
    espacioUsado = espacioUsado * 1024; // Convertimos a MB
    labelEspacio = 'Espacio Usado (MB)';
    // Opcional: Redondear a dos decimales
    espacioUsado = Math.round(espacioUsado * 100) / 100;
  } else {
    // Opcional: Redondear a dos decimales
    espacioUsado = Math.round(espacioUsado * 100) / 100;
  }

  // Ahora ajustamos data3 y options3 utilizando las variables actualizadas

  // Calcula el valor máximo de los datos en data3
  const maxValue = Math.max(photos.totallotes, espacioUsado);

  const data3 = {
    labels: ['Lotes', labelEspacio],
    datasets: [
      {
        data: [
          photos.totallotes,
          espacioUsado,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options3 = {
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        max: maxValue * 1.5, // Extiende el eje X hasta un 50% más del valor máximo
        ticks: {
          font: {
            size: 14,
          },
        },
        title: {
          display: true,
          text: 'Valores',
          font: {
            size: 16,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 14,
          },
        },
        title: {
          display: true,
          text: 'Datos de Usuario',
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'RECURSOS CONSUMIDOS',
        font: {
          size: 18,
        },
      },
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        formatter: (value) => value,
        color: 'black',
        font: {
          size: 14,
          weight: 'bold',
        },
      },
    },
  };

  return (
    <div className="placeholder-view">
      <div className="graph-row">
        <div className="chart-container">
          <Bar data={data1} options={options1} />
        </div>
        <div className="chart-container">
          <Bar data={data2} options={options2} />
        </div>
      </div>
      <div className="chart-container">
        <Bar data={data3} options={options3} />
      </div>
    </div>
  );
}

export default PlaceholderView;