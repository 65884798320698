// src/components/Granulometry.js

import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import './Granulometry.css';
import Notification from './Notification';
import { GranulometryContext } from '../context/GranulometryContext';
import LotenameTable from './LotenameTable'; 
import PhotoGranulometry from './PhotoGranulometry';
import LoteTable from './LoteTable'; 
import fetchWithAuth from '../components/fetchWithAuth';

const Granulometry = () => {
  const {
    lotenameData,
    currentPage,
    totalPages,
    setCurrentPage,
    fetchAllPhotos
  } = useContext(GranulometryContext);

  const [selectedLotename, setSelectedLotename] = useState('');
  const [loteData, setLoteData] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [granulometryImageBase64, setGranulometryImageBase64] = useState('');  // Imagen granulométrica
  const [contourImageUrl, setContourImageUrl] = useState('');  // Imagen con contornos
  const [tamizajeImageUrl, setTamizajeImageUrl] = useState('');  // Imagen de tamizaje
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [notification, setNotification] = useState(null);

  const contourImageRef = useRef(null);
  const tamizajeImageRef = useRef(null);

  const itemsPerPage = 5;

  // Definir handleSelectPhoto con loteId como parámetro
  const handleSelectPhoto = useCallback(async (photo, loteId) => {
    console.log('handleSelectPhoto called with:', { photo, loteId }); // Log para depuración
    if (isBlocked || !photo || !photo.id_foto_s3 || !loteId) {
      console.error('Error: Faltan datos. Foto o loteId no está presente.');
      setNotification('Error: No se puede procesar la imagen debido a que falta el loteId.');
      return;
    }

    setIsBlocked(true);
    setIsLoadingImage(true);
    setSelectedPhoto(photo);
    setGranulometryImageBase64('');  // Limpiar la imagen granulométrica anterior
    setContourImageUrl('');  // Limpiar la imagen de contornos anterior
    setTamizajeImageUrl(''); // Limpiar la imagen de tamizaje anterior

    try {
      // Obtener la imagen granulométrica procesada desde el endpoint de Python
      const imageResponse = await fetch(`${process.env.REACT_APP_API_PYTHON_URL}/tamizage-mask-postgres/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ image_id: photo.id_foto_s3, loteId: loteId }),  // Pasar también el loteId
      });

      if (imageResponse.ok) {
        const data = await imageResponse.json();
        setGranulometryImageBase64(`data:image/png;base64,${data.image_base64}`);
      } else {
        throw new Error('Error al obtener la imagen granulométrica.');
      }

      // Obtener la imagen de contornos desde el backend
      const contourImageResponse = await fetch(`${process.env.REACT_APP_API_DATA_URL}/photoresult/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
           id_foto_s3: photo.id_foto_s3,
           loteId: loteId,
           type: "contours"
        }),
      });

      if (!contourImageResponse.ok) {
        throw new Error('Error al obtener la imagen de contornos.');
      }

      const contourImageBlob = await contourImageResponse.blob();

      // Verificar si el blob tiene contenido
      if (contourImageBlob.size === 0) {
        throw new Error('La imagen de contornos está vacía.');
      }

      // Crear y almacenar la URL del blob
      if (contourImageRef.current) {
        URL.revokeObjectURL(contourImageRef.current); // Revocar URL anterior si existe
      }
      const newContourImageUrl = URL.createObjectURL(contourImageBlob);
      contourImageRef.current = newContourImageUrl;
      setContourImageUrl(newContourImageUrl);

      // Obtener la imagen de tamizaje desde el backend
      const tamizajeImageResponse = await fetch(`${process.env.REACT_APP_API_DATA_URL}/photoresult/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
           id_foto_s3: photo.id_foto_s3,
           loteId: loteId,
           type: "tamizaje"
        }),
      });

      if (!tamizajeImageResponse.ok) {
        throw new Error('Error al obtener la imagen de tamizaje.');
      }

      const tamizajeImageBlob = await tamizajeImageResponse.blob();

      // Verificar si el blob tiene contenido
      if (tamizajeImageBlob.size === 0) {
        throw new Error('La imagen de tamizaje está vacía.');
      }

      // Crear y almacenar la URL del blob
      if (tamizajeImageRef.current) {
        URL.revokeObjectURL(tamizajeImageRef.current); // Revocar URL anterior si existe
      }
      const newTamizajeImageUrl = URL.createObjectURL(tamizajeImageBlob);
      tamizajeImageRef.current = newTamizajeImageUrl;
      setTamizajeImageUrl(newTamizajeImageUrl);

    } catch (error) {
      console.error('Error al descargar los datos:', error);
      setNotification('Error al descargar los datos.');
    } finally {
      setIsLoadingImage(false);
      setIsBlocked(false);
    }
  }, [isBlocked]);

  // Maneja la selección de un lote
  const handleSelectLotename = useCallback(async (loteId) => {
    if (!loteId) {
      console.error('Error: LoteId está vacío.');
      setNotification('Error: LoteId no está presente.');
      return;
    }

    setIsLoadingPhotos(true);
    setSelectedLotename(loteId);
    setSelectedPhoto(null);
    setGranulometryImageBase64('');
    setContourImageUrl('');
    setTamizajeImageUrl('');

    try {
      // Obtener la información del lote
      const loteInfoResponse = await fetchWithAuth(`${process.env.REACT_APP_API_DATA_URL}/lote-info-data?loteId=${loteId}`);
      setLoteData(Array.isArray(loteInfoResponse) ? loteInfoResponse : []);

      // Obtener las fotos procesadas
      const photosResponse = await fetchWithAuth(`${process.env.REACT_APP_API_DATA_URL}/photos_process?loteId=${loteId}&filter=procesada`);
      const loadedPhotos = Array.isArray(photosResponse) ? photosResponse : [];
      setPhotos(loadedPhotos);

      // Seleccionar automáticamente la primera foto solo si hay fotos disponibles y se ha seleccionado un lote
      if (loadedPhotos.length > 0) {
        handleSelectPhoto(loadedPhotos[0], loteId); // Pasar 'photo' y 'loteId'
      }

    } catch (error) {
      console.error('Error al obtener los datos del lote o las fotos:', error);
      setNotification('Error al obtener los datos del lote o las fotos.');
    } finally {
      setIsLoadingPhotos(false);
    }
  }, [handleSelectPhoto]);

  // Obtener los datos de los lotes desde el contexto
  useEffect(() => {
    const fetchLotenameData = async () => {
      try {
        await fetchAllPhotos(currentPage);
        // lotenameData y totalPages ya están manejados por el contexto
      } catch (error) {
        console.error('Error al obtener los lotes:', error);
        setNotification('Error al obtener los lotes.');
      }
    };

    fetchLotenameData();
  }, [fetchAllPhotos, currentPage]);

  // Funciones de paginación
  const handleNextPage = useCallback(() => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  }, [totalPages, setCurrentPage]);

  const handlePreviousPage = useCallback(() => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  }, [setCurrentPage]);

  // Limpiar las URLs cuando el componente se desmonta
  useEffect(() => {
    return () => {
      if (contourImageRef.current) {
        URL.revokeObjectURL(contourImageRef.current);  // Limpiar la URL creada
      }
      if (tamizajeImageRef.current) {
        URL.revokeObjectURL(tamizajeImageRef.current);  // Limpiar la URL creada
      }
    };
  }, []);

  return (
    <div className="main-container">
      {notification && (
        <Notification
          message={notification}
          duration={3000}
          onClose={() => setNotification(null)}
        />
      )}

      {/* Primera Tabla */}
      <div className="full-width-table-container">
        <LotenameTable
          lotenameData={lotenameData}
          currentPage={currentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          handleSelectLotename={handleSelectLotename}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          disabled={isBlocked}
        />
      </div>

      {/* Sección de imágenes y tablas */}
      {selectedLotename && (
        <div className="content-container">
          <div className="image-section">
            <div className="granulometry-container">
              {isLoadingImage ? (
                <div className="spinner"></div>
              ) : (
                selectedPhoto && granulometryImageBase64 ? (
                  <img src={granulometryImageBase64} alt="Imagen Granulométrica" />
                ) : (
                  <p>Seleccione una Foto</p>
                )
              )}
            </div>
            <div className="contour-container">
              {isLoadingImage ? (
                <div className="spinner"></div>
              ) : (
                selectedPhoto && contourImageUrl ? (
                  <img src={contourImageUrl} alt="Imagen con Contornos" />
                ) : (
                  <p>Seleccione una Foto o la imagen de contornos no está disponible</p>
                )
              )}
            </div>
            <div className="tamizaje-container">
              {isLoadingImage ? (
                <div className="spinner"></div>
              ) : (
                selectedPhoto && tamizajeImageUrl ? (
                  <img src={tamizajeImageUrl} alt="Imagen de Tamizaje" />
                ) : (
                  <p>Seleccione una Foto</p>
                )
              )}
            </div>
          </div>

          <div className="table-section">
            {/* Tabla de Fotos */}
            <div className="table-container">
              {isLoadingPhotos || isLoadingImage ? (
                <div className="loading-overlay">
                  <div className="spinner"></div>
                </div>
              ) : (
                <PhotoGranulometry
                  photos={photos}
                  selectedPhoto={selectedPhoto}
                  handleSelectPhoto={!isLoadingImage && !isBlocked ? (photo) => handleSelectPhoto(photo, selectedLotename) : () => {}}
                />
              )}
            </div>
            {/* Tabla de Información del Lote */}
            <div className="table-container">
              <LoteTable loteData={loteData} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Granulometry;
